import React from "react";
import * as styles from "./social_icon_link.module.scss"

export default function SocialIconLink(props) {
   return (
       <a href={props.destination}>
       <div className={styles.container}>

               {props.children}

       </div>
       </a>
   )
}