import React from "react";
import Layout from "../components/layout";
import FbIcon from "../images/FB_icon.svg"
import InstaIcon from "../images/INSTA_icon.svg"
import MessengerIcon from "../images/MESSENGER_icon.svg"
import SocialIconLink from "../components/social_icon_link";
import * as styles from "./contact.module.scss"

export default function Contact() {
    return (
        <Layout>
            <main className={styles.main}>
            <h1>Contact!!</h1>
            <p>Have any questions? Do you want to know more?<br/> Or maybe you just want a little chat...</p>
            <p>Send an email to <b>nyb@nowhere.com</b></p>
            <p>Or reach out on on of the following!</p>
            <div className={styles.socialContainer}>
                <SocialIconLink destination={"https://www.instagram.com/anneleenpieters/"}>
                    <InstaIcon />
                </SocialIconLink>
                <SocialIconLink destination={"https://m.facebook.com/anneleen.pieters.58"}>
                    <FbIcon />
                </SocialIconLink>
                <SocialIconLink destination={"http://m.me/anneleen.pieters.58"}>
                    <MessengerIcon />
                </SocialIconLink>
            </div>
            </main>
        </Layout>
    )
}